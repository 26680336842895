import defaultsToVars, { defaultsToCss } from '../lib/cssVarsUtils'

const sizesPrefix = 'sizes'

export const sizes = {
  pdpContentWidth: '890px',
  containerWidth: '1440px',
  containerXlWidth: '1920px',
  uspBarHeight: '42px',
  headerHeight: '46px',
  searchInputMaxWidth: '450px',
  inputHeight: '50px',
  fullScreenModalHeaderHeight: '60px',
  searchInputHeight: '40px',
  headerCollapsibleHeight: '50px',
  menuMobileHeaderHeight: '175px',
  logoWidth: '65px',
  cartColumnWidth: '320px',
}

export const sizesLg = {
  pdpContentWidth: '890px',
  containerWidth: '1440px',
  containerXlWidth: '1920px',
  uspBarHeight: '42px',
  headerHeight: '87px',
  searchInputMaxWidth: '450px',
  inputHeight: '50px',
  fullScreenModalHeaderHeight: '60px',
  searchInputHeight: 'auto',
  headerCollapsibleHeight: '50px',
  menuMobileHeaderHeight: '175px',
  logoWidth: '110px',
  cartColumnWidth: '320px',
}

export const sizesXl = {
  pdpContentWidth: '890px',
  containerWidth: '1440px',
  containerXlWidth: '1920px',
  uspBarHeight: '42px',
  headerHeight: '87px',
  searchInputMaxWidth: '450px',
  inputHeight: '50px',
  fullScreenModalHeaderHeight: '60px',
  searchInputHeight: 'auto',
  headerCollapsibleHeight: '50px',
  menuMobileHeaderHeight: '175px',
  logoWidth: '110px',
  cartColumnWidth: '450px',
}

export const sizesCss = [...defaultsToCss(sizes, sizesPrefix)]
export const sizesLgCss = [...defaultsToCss(sizesLg, sizesPrefix)]
export const sizesXlCss = [...defaultsToCss(sizesXl, sizesPrefix)]

export const imageSizes = {
  // Use for full width components
  fullWidth: {
    width: 2500,
    sizes: {
      xs: 767,
      md: 992,
      lg: 1310,
      xl: 1800,
      1800: 2500,
    },
  },
  // Use for 50/50 column components
  halfWidth: {
    width: 1250,
    sizes: {
      xs: 767,
      md: 992,
      lg: 1250,
      xl: 1250,
    },
  },
  // Use for GenericBlockLink images
  square: {
    width: 1250,
    sizes: {
      xs: 600,
      md: 600,
      lg: 1250,
      xl: 1250,
    },
  },
  // Use for highlight banner images
  highlightBanner: {
    width: 2500,
    sizes: {
      xs: 767,
      md: 1982,
      lg: 2500,
      xl: 2500,
    },
  },
  // Use for product gallery images
  productGalleryImage: {
    width: 1920,
    sizes: {
      xs: 1534,
      md: 1982,
      lg: 1920,
      xl: 1920,
    },
  },
  // Use for product card images
  productCard: {
    width: 800,
    sizes: {
      xs: 800,
      md: 800,
      lg: 800,
      xl: 800,
    },
  },
  // Use for store card images
  storeCard: {
    width: 530,
    sizes: {
      xs: 530,
      md: 530,
      lg: 430,
      xl: 430,
    },
  },
  // Use for store card images
  storeImage: {
    width: 1440,
    sizes: {
      xs: 722,
      md: 991,
      lg: 1440,
      xl: 1440,
    },
  },
  // Use for page header images
  pageHeader: {
    width: 2500,
    sizes: {
      xs: 1534,
      md: 1982,
      lg: 2500,
      xl: 2500,
    },
  },
}

export default {
  sizes: defaultsToVars(sizes, sizesPrefix),
  imageSizes,
}
